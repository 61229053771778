import { render, staticRenderFns } from "./OurTeamGallery.vue?vue&type=template&id=05172269&scoped=true&"
var script = {}
import style0 from "./OurTeamGallery.vue?vue&type=style&index=0&id=05172269&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05172269",
  null
  
)

export default component.exports